<template lang="html">
  <LabelAndMessage>
    <div class="inputs">
      <template v-for="(zone, index) in sortedZones">
        <div :key="zone.id" class="input-row" :class="{'hide-zone-labels': hideZoneLabels}">
          <label v-if="!hideZoneLabels" class="zone-label">
            <i class="fa fa-square" :style="{color: zoneColors[zone.id]}" />
            <span>Zone {{ zone.number }}</span>
            <small class="area-stats">({{ $i18n.format(zone.area, 'area') }} = {{ $i18n.format(zone.area / totalArea, 'percent') }})</small>
          </label>

          <slot
            :zone="zone"
            :index="index"
            :value="value && value[zone.id] !== undefined ? value[zone.id] : null"
            :on-input="x => onInput(zone.id, x)"
          />
        </div>
      </template>
    </div>

    <template v-if="$slots.label" #label>
      <slot name="label" />
    </template>
    <template v-if="$slots.description" #description>
      <slot name="description" />
    </template>
  </LabelAndMessage>
</template>

<script>
import {sortBy} from 'lodash'

import {mapToColorLookup} from '@helpers/map-color-schemes'

import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'

export default {
  components: {
    LabelAndMessage
  },
  props: {
    value: {
      validator: x => x === null || (x && (x.toString() === '[object Object]')), // {[zoneId]: value}
      required: true
    },
    zoneMap: {
      type: Object,
      required: true
    },
    palette: {
      type: String,
      default: 'agrosat'
    },
    hideZoneLabels: Boolean
  },
  computed: {
    sortedZones () {
      return sortBy(this.zoneMap.zones, ['number', 'name'])
    },
    totalArea () {
      return this.zoneMap.zones.reduce((total, zone) => total + zone.area, 0)
    },
    zoneColors () {
      return mapToColorLookup(this.zoneMap, this.palette)
      // const zoneNumbers = orderBy(this.applicationMap.zones, ['name'], ['desc'])[0].name
      // return colors.managementZoneMap[zoneNumbers].reverse()
    }
  },
  methods: {
    onInput (zoneId, value) {
      this.$emit('input', {
        ...this.value,
        [zoneId]: value
      })
    }
  },
  watch: {
    'zoneMap': {
      immediate: true,
      deep: true,
      handler (zoneMap) {
        if (!zoneMap.zones) {
          this.$emit('input', null)
          return
        }
        this.$emit('input', zoneMap.zones.reduce((lookup, zone) => {
          lookup[zone.id] = (this.value && this.value[zone.id] !== undefined) ? this.value[zone.id] : null
          return lookup
        }, {}))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.inputs, .input-row {
  display: flex;
  flex-direction: column;
}

.input-row:not(:first-child) {
  margin-top: 0.5em;
}

.zone-label {
  font-size: 0.9em;

  display: flex;
  align-items: baseline;
  margin-bottom: 0.1em;

  .fa {
    margin-right: 0.25em;
  }

  .area-stats {
    color: gray;
    margin-left: 0.5em;
  }
}
</style>
