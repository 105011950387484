<template lang="html">
  <div class="zone-map-additional-values">
    <h3>
      <IxRes>frs.mapManagement.headers.zoneMapAdditionalValues</IxRes>
    </h3>

    <div v-if="zoneMap && values" class="zone-input-row">
      <ZoneInput
        v-model="values"
        :zone-map="zoneMap"
      >
        <template #default="{zone, value, onInput}">
          <div class="input-row">
            <VueSelectize
              :value="value && value.waterAvailability !== undefined ? value.waterAvailability : null" :options="waterAvailabilityOptions"
              :name="`waterAvailability-input-${zone.id}`"
              :fields="waterAvailabilityFields"
              allow-empty
              @input="x => onInput({...defaultRow, ...value, waterAvailability: x})"
            >
              <template #label>
                <IxRes>frs.mapManagement.zoneMapAdditionalValues.labels.waterAvailability</IxRes>
              </template>
            </VueSelectize>

            <NumericInput
              :value="(value && value.acreRatio) || null"
              :name="`acreRatio-input-${zone.id}`"
              :rule="between(0, 106)"
              @input="x => onInput({...defaultRow, ...value, acreRatio: x})"
            >
              <template #label>
                <IxRes>frs.mapManagement.zoneMapAdditionalValues.labels.acreRatio</IxRes>
              </template>
            </NumericInput>

            <NumericInput
              :value="(value && value.pwc) || null"
              :name="`pwc-input-${zone.id}`"
              :rule="between(60, 420)"
              @input="x => onInput({...defaultRow, ...value, pwc: x})"
            >
              <template #label>
                <IxRes>frs.mapManagement.zoneMapAdditionalValues.labels.pwc</IxRes>
              </template>
            </NumericInput>
          </div>
        </template>
      </ZoneInput>
    </div>

    <div class="buttons">
      <IxButton large cancel @click="onCancel" />
      <IxButton
        large :disabled="inputIsLegit"
        save @click="onSave"
      />
    </div>

    <FrsLoadingIndicator :loading="saving" />
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import {mapFormFields, mapResources} from '@helpers/vuex'
import {waterAvailabilities} from 'src/js/data/zone-map-additional-value-enums'
import {notifications} from 'src/js/infrastructure'

import IxButton from '@components/IxButton'
import ZoneInput from '@components/forms/ZoneInput'
import NumericInput from '@components/forms/NumericInput'
import VueSelectize from '@components/VueSelectize'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import MirrorRouteMixin from '@frs/mixins/MirrorRouteMixin'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'

export default {
  components: {
    IxButton,
    ZoneInput,
    NumericInput,
    VueSelectize,
    FrsLoadingIndicator
  },
  mixins: [
    MirrorRouteMixin,
    StandardRulesMixin
  ],
  data () {
    return {
      saving: false
    }
  },
  computed: {
    ...mapResources({
      parameterTranslations: 'frs.applicationMaps.parameters'
    }),
    ...mapResources([
      'frs.mapManagement.zoneMapAdditionalValues.notifications'
    ]),
    ...mapState('fieldRecordSystem/mapManagement', {
      zoneMapLookup: state => state.data.zoneMap.mapLookup
    }),
    ...mapFormFields('fieldRecordSystem/mapManagement/zoneMapAdditionalValues', [
      'values'
    ]),
    zoneMap () {
      return this.zoneMapLookup[this.$route.params.mapId]
    },
    waterAvailabilityFields () {
      return {
        text: value => this.parameterTranslations[`waterAvailability_${waterAvailabilities.find(x => x.value === value).name}`]
      }
    },
    waterAvailabilityOptions () {
      return waterAvailabilities.map(x => x.value)
    },
    defaultRow () {
      return {
        waterAvailability: null,
        acreRatio: null,
        pwc: null
      }
    },
    inputIsLegit () {
      var waterAvailabilitiesAreNull = this.zoneMap.zones.map(x => this.values[x.id].waterAvailability === null)
      var waterAvailabilitiesAreLegit = waterAvailabilitiesAreNull.includes(false) && waterAvailabilitiesAreNull.includes(true)

      var valueRulesAreLegit = this.zoneMap.zones.map(x => this.values[x.id]).find(x =>
        x.acreRatio > 106 || x.acreRatio < 0 ||
        x.pwc > 420 || x.pwc < 60
      ) != null

      return valueRulesAreLegit || waterAvailabilitiesAreLegit
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/mapManagement/zoneMapAdditionalValues', [
      'save',
      'setupEditValues'
    ]),
    onCancel () {
      this.$router.push({name: this.createParallelRoute('mapDetails'), params: {...this.$route.params}})
    },
    async onSave () {
      this.saving = true

      try {
        await this.save(this.$route.params.mapId)
        notifications.success(this.notifications.saveSuccess)
      } catch (error) {
        console.error(error)
        notifications.error(this.notifications.saveError)
      } finally {
        this.saving = false
        this.$router.push({name: this.createParallelRoute('mapDetails'), params: {...this.$route.params}})
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setupEditValues(to.params.mapId)
    })
  }
}
</script>

<style lang="scss" scoped>
.zone-map-additional-values {
  position: relative;
}

.input-row {
  display: flex;

  > * {
    flex: 1;
  }

  > :not(:first-child) {
    margin-left: 0.5em;
  }
}

.input-row ::v-deep label.control-label {
  font-size: 0.8em;
  color: gray;
}
</style>
