export const waterAvailabilities = [
  {
    value: -1,
    name: 'Unknown'
  },
  {
    value: 1,
    name: 'GroundWaterNear'
  },
  {
    value: 0,
    name: 'GroundWaterFar'
  },
  {
    value: 2,
    name: 'Waterlogging'
  }
]
